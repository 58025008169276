import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="About Me" keywords={[`blog`, `about`, `mengxi`]} />
        <div>
          <h1>About Me</h1>
        </div>

        <p>
          My name is Mengxi Lu, I am building FinTech products at{' '}
          <a href="https://www.lendup.com/" target="_blank">
            LendUp
          </a>
          ; previously at Oyster, Tumblr and comScore; 83% engineer, 31% photographer, very good at math.
        </p>
        <p>
          I appreciate fine arts, good food, and am obsessed with horology. You can also find me on{' '}
          <a href="https://twitter.com/mengxilu" target="_blank">
            Twitter
          </a>
          ,{' '}
          <a href="https://www.linkedin.com/in/mengxilu" target="_blank">
            LinkedIn
          </a>
          , and{' '}
          <a href="https://lumengxi.500px.com/home" target="_blank">
            500px
          </a>
          .
        </p>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
